@import "../../../../styles/variables";

.creativeContainer {
  max-width: 1370px;
  width: 100%;
  margin: auto;
  padding: 80px 15px;

  .creativeSubContainer {
    max-width: 819px;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: auto;
    color: $black;

    .creativeHeading {
      font-weight: 700;
      font-size: 40px;
      line-height: 52px;
      margin-bottom: 12px;
      text-transform: uppercase;
    }
    .creativesubHeading {
      font-weight: 700;
      font-size: 24px;
      text-transform: uppercase;
    }
    .creativeDisc {
      font-size: 16px;
      text-align: center;
      line-height: 28px;
      margin: 15px 0 20px 0;
    }
    .creativeBtns {
      display: flex;
      gap: 20px;
      justify-content: center;
      margin-bottom: 50px;
      flex-wrap: wrap;
      .creativePrimaryBtn {
        border-radius: 50px;
        width: 220px;
        height: 62px;
        font-size: 16px;
        font-weight: 500;
        color: $body-bg;
        background-color: $primary;
        border: none;
        outline: none;
        text-align: center;
        cursor: pointer;
      }
      .creativeSecondaryBtn {
        border-radius: 50px;
        width: 220px;
        height: 62px;
        font-size: 16px;
        font-weight: 500;
        color: $primary;
        border: 1px solid $tertiary-border;
        outline: none;
        text-align: center;
        background-color: transparent;
        cursor: pointer;
      }
    }
  }
  .creativeBoxContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
    gap: 20px;

    .creativeBox {
      width: 100%;
      height: 420px;
      position: relative;
      border-radius: 20px;
      overflow: hidden;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(to top, rgba($primary, 0.9) 0%, transparent 30%);
        z-index: 1;
      }

      .creativeBoxData {
        position: absolute;
        bottom: 0px;
        top: auto;
        left: 0;
        right: 0;
        display: flex;
        margin: auto;
        flex-direction: column;
        padding: 20px 10px;
        align-items: center;
        z-index: 2;

        .creativeBoxheading {
          font-size: 22px;
          font-weight: 500;
          color: $white;
          margin-bottom: 10px;
        }
        .creativeBoxList {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          gap: 10px;

          .creativeBoxListItem {
            border: 1px solid $white;
            font-size: 12px;
            padding: 4px 5px;
            color: $white;
            text-transform: capitalize;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 642px) {
  .creativeContainer .creativeSubContainer {
    .creativeHeading {
      font-size: 24px;
      line-height: 40px;
    }
    .creativesubHeading {
      font-size: 18px;
    }
    .creativeDisc {
      font-size: 16px;
      text-align: center;
    }
    .creativeBtns {
      flex-direction: column;
      .creativePrimaryBtn {
        width: 80%;
        margin: auto;
        height: 45px;
        font-size: 14px;
      }
      .creativeSecondaryBtn {
        margin: auto;
        width: 80%;
        height: 45px;
        font-size: 14px;
      }
    }
  }
  .creativeContainer {
    padding: 40px 15px;
    .creativeBoxContainer .creativeBox {
      height: 342px;
      .creativeBoxData {
        .creativeBoxheading {
          font-size: 16px;
        }
        .creativeBoxList {
          .creativeBoxListItem {
            font-size: 10px;
            padding: 3px 5px;
          }
        }
      }
    }
  }
}
